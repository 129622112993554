<template>
    <div class="">
        <iframe
            :src="'/filemanager/dialog.php?ADM_ID=' + $store.state.user.id + '&token=' + $store.state.user.login_token"
            style="width: 100%;height: calc(100vh - 100px);border:1px solid #ccc"
        ></iframe>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                product_number: 0,
                contact_number: 0,
            };
        },
    };
</script>
